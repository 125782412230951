<template>
  <div class="pl-4">
    <b-alert show variant="success" v-if="manufacturer.status === 1 /* коректировка */">
      <p>
        На этапе проверки своих данных производитель запросил изменение.<br />
        Сообщение от производителя:
      </p>
      <p>{{ manufacturer.comment }}</p>
    </b-alert>
    <div class="mb-3 row">
      <div class="pl-5 ml-1 col">
        <h2 class="h4">Производитель {{ manufacturer.name }}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex">
        <ul class="mr-5 list">
          <li><b>ИНН</b>{{ manufacturer.inn }}</li>
          <li><b>Менеджер</b>{{ manufacturer.manager }}</li>
          <li><b>Контактное лицо</b>{{ manufacturer.contactPerson }}</li>
          <li><b>Статус</b>{{ manufacturerStatusText }}</li>
        </ul>

        <ul class="list">
          <li><b>Фактический адрес</b>{{ manufacturer.address }}</li>
          <li><b>Телефон</b>{{ manufacturer.contactPersonPhone }}</li>
          <li v-if="manufacturer.contactPersonMobilePhone"><b>Телефон моб.</b>{{ manufacturer.contactPersonMobilePhone }}</li>
          <li v-if="manufacturer.webSite"><b>Сайт</b>{{ manufacturer.webSite }}</li>
          <li><b>Email</b>{{ manufacturer.contactPersonEmail }}</li>
        </ul>
      </div>

      <div class="col-auto pr-5 text-right" v-if="!manufacturer.hasCertificate && !isInCertificationProcess">
        <p><b>У производителя нет действующего сертификата</b></p>
        <b-button variant="success" v-if="isManager || isLeader" v-b-modal.modalAddCertifaicate>Добавить сертификат</b-button>
      </div>

      <div class="col-auto pr-5 text-right" v-if="isInCertificationProcess">
        <p>
          <b class="text-danger">
            Производителю отправлено сообщение для<br />
            подтверждения выдачи сертификата
          </b>
        </p>
      </div>
    </div>

    <div class="row" v-if="(isManager || isLeader) && manufacturerStatus == 1">
      <div class="pl-5 col">
        <b-button variant="success" @click="onManufacturerEdit">Редактировать</b-button>
      </div>
    </div>

    <div class="pt-5 row ">
      <div class="pl-5 col-full">
        <b-tabs content-class="mt-3" ref="tabs" v-model="tabIndex">
          <b-tab title="Сертификаты" active>
            <div v-if="!certificateHistoryTotal">
              Нет истории сертификатов
            </div>
            <b-table
              v-if="certificateHistoryTotal"
              hover
              selectable
              select-mode="single"
              :items="certificatesHistory"
              :fields="historyFields"
              :sort-by.sync="certificatesSortBy"
              :sort-desc.sync="certificatesSortDesc"
              @row-selected="onHistoryRowSelected"
            >
              <template #cell(bitlyUrl)="data">
                <a :href="data.value" target="_blanck" class="link">{{ data.value }}</a>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Партнеры">
            <div v-if="!manufacturerDealersTotal">
              Нет зарегистрированных партнеров
            </div>
            <b-table
              v-if="manufacturerDealersTotal"
              hover
              selectable
              select-mode="single"
              :items="manufacturerDealers"
              :fields="dealersFields"
              @row-selected="onPartnersRowSelected"
            ></b-table>
          </b-tab>
          <b-tab title="Сертификаты собственной розницы">
            <div v-if="!selfretailsHistoryTotal">
              Нет истории сертификатов точек собственной розницы
            </div>
            <b-table
              v-if="selfretailsHistoryTotal"
              hover
              selectable
              select-mode="single"
              :items="selfretailsHistory"
              :fields="selfretailsHistoryFields"
              @row-selected="onSelfRetailHistoryRowSelected"
            >
              <template #cell(bitlyUrl)="data">
                <a :href="data.value" target="_blanck" class="link">{{ data.value }}</a>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Офисы собственной розницы">
            <div v-if="!selfretailsTotal">
              Нет зарегистрированных точек собственной розницы
            </div>
            <b-table
              v-if="selfretailsTotal"
              hover
              selectable
              select-mode="single"
              :items="selfretails"
              :fields="selfretailsFields"
              @row-selected="onSelfRetailRowSelected"
            ></b-table>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col"></div>
    </div>

    <div class="mt-3 mb-5 row" v-if="tabIndex == 1 && partnerRowSelected">
      <div class="pl-5 col">
        <b-button class="mr-2 btn-danger" v-b-modal.modalDeletePartner v-if="isAdmin">Удалить партнера</b-button>
        <b-button variant="success" class="mr-3" @click="onPartnersShowRowSelected">Посмотреть</b-button>
        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
      </div>
    </div>

    <div class="mt-3 mb-5 row" >
      <div class="pl-5 col" v-if="(!!historyRowSelect && tabIndex == 0) || (tabIndex == 2 && !!selfretailsHistoryRowSelect)">
        <b-button class="mr-2 btn-danger" v-b-modal.modalDeleteCertificate>Удалить сертификат</b-button>
        <template v-if="((tabIndex == 0 && historyRowSelected) || (tabIndex == 2 && selfretailsHistoryRowSelected)) && tabIndex !== 1">
          <a :href="pdfHref" class="mr-3 btn btn-success">Загрузить</a>
          <b-button variant="success" class="mr-3" @click="showCertificate(tabIndex)">Посмотреть</b-button>
          <b-button v-if="isLeader || isManager" variant="success" class="mr-3" v-b-modal.modalRevokeCertifaicate>Аннулировать</b-button>
          <b-button v-if="showRenewButton" variant="success" class="mr-3" @click="onRenewCertificate">Продлить</b-button>
          <b-button v-if="showHistoryButton" variant="success" class="mr-3" v-b-modal.modalHistoryCertifaicate>История</b-button>
        </template>
        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
      </div>
    </div>
    <div class="mt-3 mb-5 row" v-if="tabIndex == 3 && selfretailsRowSelected && isAdmin">
      <div class="pl-5 col">
        <b-button class="mr-2 btn-danger" v-b-modal.modalDeleteSelfRetail v-if="isAdmin">Удалить офис</b-button>
        <b-button variant="success" class="mr-3" :disabled="isEditDisabled" @click="onSelfRetailEdit">Редактировать</b-button>
        <span v-if="isEditDisabled" class="text-danger">Данная точка не подтверждена и не может быть отредактирована</span>
      </div>
    </div>

    <RightSidebar
      :form="idEditFormName"
      :form-data="idEditFormData"
      :mode="idEditFormMode"
      :show="idEditFormShow"
      @update:show="idEditFormShow = $event;idEditFormData = {};"
      @saved:form-data="onSavedEditForm"
    ></RightSidebar>

    <b-modal id="modalDeleteCertificate" title="Удалить сертификат" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeleteCertificate">
      Подтвердите удаление сертификата
    </b-modal>

    <b-modal id="modalDeletePartner" title="Удалить партнера" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeletePartner">
      Подтвердите удаление партнера
    </b-modal>

    <b-modal id="modalDeleteSelfRetail" title="Удалить офис" ok-title="Подтвердить" cancel-title="Отмена" centered @ok="onDeleteSelfRetail">
      Подтвердите удаление офиса
    </b-modal>

    <b-modal id="modalAddCertifaicate" title="Укажите срок действия сертификата" ok-title="Добавить" cancel-title="Отмена" centered @ok="onAddCertificate">
      <b-form-datepicker id="example-datepicker" v-model="certificateDate" :min="todayDate" class="mb-2"></b-form-datepicker>
    </b-modal>

    <b-modal id="modalRevokeCertifaicate" title="Укажите дату аннулирования сертификата" ok-title="Аннулировать" cancel-title="Отмена" centered @ok="onRevokeCertificate">
      <b-form-datepicker v-model="certificateRevokeDate" :min="todayDate" class="mb-2"></b-form-datepicker>
    </b-modal>

    <b-modal id="modalHistoryCertifaicate" title="История продления сертификата" ok-only ok-title="Закрыть" centered @show="onLoadHistoryCertificate">
      <div class="row">
        <div class="col">
          <b-table ref="selectableTable" hover selectable select-mode="single" :fields="modalHistoryFields" :items="certifyReReleaseHistory" :no-local-sorting="true"></b-table>
          <div v-if="!certifyReReleaseHistoryTotal" class="text-center">Нет записей</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from "vuex";
import { DateFormat } from "@/helpers";
import { PartnerStatus } from "@/constants";
import { RightSidebar } from "@/components";

export default {
  name: "ManufacturerDetails",
  components: { RightSidebar },
  data() {
    const now = new Date();
    const year = now.getFullYear();
    var month = now.getMonth();
    var day = now.getDate();
    const date = `${year + 1}-${month + 1}-${day}`;
    const todayDate = `${year}-${month + 1}-${day}`;
    return {
      loading: false,
      tabIndex: null,

      allowRevokeCertificate: false,
      activeCertificateKey: null,
      activeSelfRetailKey: null,
      pdfHref: "",

      certificatesSortBy: "dateOfIssue",
      certificatesSortDesc: true,
      certificatesButtonsVisibility: false,

      todayDate: todayDate,
      certificateRevokeDate: todayDate,
      certificateDate: date,

      manufacturerStatusText: "",
      manufacturerId: this.$route.params.id,

      idEditFormName: null,
      idEditFormData: {},
      idEditFormMode: "EDIT",
      idEditFormShow: false,

      historyFields: [
        { key: "dateOfIssue", label: "Дата", formatter: item => DateFormat.getDate(item) },
        { key: "expirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) },
        { key: "status", label: "Статус" },
        { key: "numberOfRerelease", label: "Пролонгирован", tdClass: "text-center" },
        { key: "bitlyUrl", label: "Ссылка на сертификат" },
      ],
      historyRowSelect: null,
      partnerRowSelected: null,

      dealersFields: [
        { key: "inn", label: "ИНН" },
        { key: "name", label: "Наименование" },
        { key: "hasCertificate", label: "Сертификат", formatter: item => (item ? "Да" : "Нет") },
        { key: "certificateExpirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) }
      ],

      selfretailsHistoryFields: [
        { key: "spotName", label: "Наименование" },
        { key: "spotAddress", label: "Фактический адрес" },
        { key: "dateOfIssue", label: "Дата", formatter: item => DateFormat.getDate(item) },
        { key: "expirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) },
        { key: "status", label: "Статус" },
        { key: "numberOfRerelease", label: "Пролонгирован", tdClass: "text-center" },
        { key: "bitlyUrl", label: "Ссылка на сертификат" },
      ],
      selfretailsHistoryRowSelect: null,

      selfretailsFields: [
        { key: "id", label: "ID" },
        { key: "spotName", label: "Наименование" },
        { key: "spotAddressCoordinates", label: "Координаты" },
        { key: "spotAddress", label: "Фактический адрес" },
        { key: "spotAddressPhone", label: "Телефон" },
        { key: "spotAccost", label: "Менеджер" },
        { key: "hasCertificate", label: "Сертификат", formatter: item => (item ? "Да" : "Нет") },
        { key: "expirationDate", label: "Действителен до", formatter: item => DateFormat.getDate(item) }
      ],
      selfretailsRowSelect: null,

      modalHistoryFields: [
        { key: "createdAt", label: "Дата продления", formatter: item => DateFormat.getDate(item) },
        { key: "createdBy", label: "Продлил" }
      ]
    };
  },
  methods: {
    ...mapActions("manufacturers", [
      "isManufacturerInCertificationProcess",
      "editManufacturer",
      "editSelfRetail",
      "fetchManufacturerById",
      "fetchManufacturerHistory",
      "fetchManufacturerPartnersWithCertInfo",
      "fetchSelfRetailCertificateHistoryByManufacturerId",
      "fetchSelfRetails",
      "revokeCertificate",
      "requestCertificate",
      "selfretailCertificateRevocation",
      "certifyReRelease",
      "fetchCertifyReReleaseHistory",
      "fetchSelfretailCertifyReReleaseHistory",
      "deleteManufacturersCertificate",
      "deleteManufacturersSelfRetail",
      "deleteSelfretailCertificate",
    ]),
    ...mapActions("partners", ["deletePartner"]),

    async onDeleteCertificate() {
      this.loading = true;

      if (this.tabIndex === 0) {
        this.deleteManufacturersCertificate({
          manufacturerId: this.manufacturerId,
          certificateId: this.historyRowSelect.id
        }).then(async (res) => {
          await this.fetchManufacturerHistory(this.manufacturerId);
          this.loading = false;

          if (res.ok) {
            this.historyRowSelect = undefined;
          }
        });
        return;
      }

      if (this.tabIndex === 2) {
        this.deleteSelfretailCertificate(this.selfretailsHistoryRowSelect.id).then(async (res) => {
          await this.fetchSelfRetailCertificateHistoryByManufacturerId(this.manufacturerId);
          this.loading = false;

          if (res.ok) {
            this.selfretailsHistoryRowSelect = undefined;
          }
        });
        return;
      }

      this.loading = false;
    },

    async onDeletePartner() {
      this.loading = true;
      this.deletePartner(this.partnerRowSelected[0].id).then(async (res) => {
        await this.fetchManufacturerPartnersWithCertInfo(this.manufacturerId);
        this.loading = false;

        if (res.ok) {
          this.partnerRowSelected = undefined;
        }
      });
    },

    async onDeleteSelfRetail() {
      this.loading = true;
      this.deleteManufacturersSelfRetail(this.selfretailsRowSelect.id).then(async (res) => {
        await this.fetchSelfRetails({ id: this.manufacturerId, params: { first: 0, rows: -1 } });
        this.loading = false;
        if (res.ok) {
          this.selfretailsRowSelect = undefined;
        }
      });
    },

    onHistoryRowSelected(items) {
      this.historyRowSelect = (items || [])[0];
    },
    onPartnersRowSelected(items) {
      this.partnerRowSelected = items;
    },
    onPartnersShowRowSelected() {
      this.$router.push(`/partners/${(this.partnerRowSelected || [])[0].id}`);
    },
    onSelfRetailHistoryRowSelected(items) {
      this.selfretailsHistoryRowSelect = (items || [])[0];
    },
    onSelfRetailRowSelected(items) {
      this.selfretailsRowSelect = (items || [])[0];
    },

    onManufacturerEdit() {
      this.idEditFormName = "ManufacturerForm"
      this.idEditFormData = { ...this.manufacturer, ...{
        manufacturerId: this.manufacturerId
      }};
      this.idEditFormShow = true;
    },
    onSelfRetailEdit() {
      this.idEditFormName = "SelfRetailForm"
      this.idEditFormData = { ...this.selfretailsRowSelect };
      this.idEditFormShow = true;
    },

    onSavedEditForm() {
      this.fechAllViewData()
    },

    showCertificate(tabIndex) {
      if (tabIndex === 0 /* certificate history */) {
        let route = this.$router.resolve(`/certificate?Key=${this.historyRowSelect.key}`);
        window.open(route.href, "_blank");
      } else if (tabIndex === 2 /* self retails certificate history */) {
        let route = this.$router.resolve(`/certificateselfretail?Key=${this.selfretailsHistoryRowSelect.key}`);
        window.open(route.href, "_blank");
      }
    },

    onAddCertificate() {
      let certData = {
        manufacturerId: this.manufacturer.id,
        certificateExpirationDate: this.certificateDate
      };
      this.requestCertificate(certData);
    },
    onRevokeCertificate() {
      let revokeCertData = {
        manufacturerId: this.manufacturer.id,
        revocationDate: this.certificateRevokeDate
      };
      if (this.tabIndex === 0 /* manufacturer certificates */)
        this.revokeCertificate(revokeCertData).then(() => {
          this.fechAllViewData();
        });
      else if (this.tabIndex === 2 /* self retail certificates */) {
        const data = {
          key: this.selfretailsHistoryRowSelect.key,
          payload: revokeCertData
        };
        this.selfretailCertificateRevocation(data).then(() => {
          this.fechAllViewData();
        });
      }
    },

    onRenewCertificate() {
      this.loading = true;
      this.certifyReRelease(this.historyRowSelect.id).then(() => {
        this.fetchManufacturerHistory(this.manufacturerId);
        this.fetchSelfRetailCertificateHistoryByManufacturerId(this.manufacturerId);
        this.loading = false;
      });
    },
    onLoadHistoryCertificate() {
      if (this.tabIndex === 0) this.fetchCertifyReReleaseHistory(this.historyRowSelect.id);
      else if (this.tabIndex === 2) this.fetchSelfretailCertifyReReleaseHistory(this.selfretailsHistoryRowSelect.id);
    },

    fechAllViewData() {
      if (this.manufacturerId) {
        this.isManufacturerInCertificationProcess(this.manufacturerId);
        this.fetchManufacturerById(this.manufacturerId);
        this.fetchManufacturerHistory(this.manufacturerId);
        this.fetchManufacturerPartnersWithCertInfo(this.manufacturerId);
        this.fetchSelfRetailCertificateHistoryByManufacturerId(this.manufacturerId);
        this.fetchSelfRetails({ id: this.manufacturerId, params: { first: 0, rows: -1 } });
      }

      this.allowRevokeCertificate = this.isManager || this.isLeader;
    }
  },
  watch: {
    historyRowSelected(val) {
      if (val && this.historyRowSelect) {
        this.activeCertificateKey = this.historyRowSelect.key;
        this.pdfHref = `${process.env.VUE_APP_API}/manufacturerscertificates/pdf/${this.activeCertificateKey}`;
      } else {
        this.activeCertificateKey = null;
        this.pdfHref = "";
      }
    },
    selfretailsHistoryRowSelected(val) {
      if (val && this.selfretailsHistoryRowSelect) {
        this.activeCertificateKey = this.selfretailsHistoryRowSelect.key;
        this.pdfHref = `${process.env.VUE_APP_API}/selfretailcertificates/pdf/${this.activeCertificateKey}`;
      } else {
        this.activeCertificateKey = null;
        this.pdfHref = "";
      }
    },

    manufacturer(val) {
      this.manufacturerStatusText = PartnerStatus.translate(val.status);
    }
  },
  computed: {
    ...mapGetters(["userRole", "isAuth", "isAdmin", "isLeader", "isManager", "isCurator", "isManufacturer", "isDealer"]),
    ...mapState("manufacturers", {
      manufacturer: state => state.manufacturer,
      manufacturerStatus: state => state.manufacturer.status || 0,
      certificate: state => state.certificate,
      isInCertificationProcess: state => state.isInCertificationProcess,
      certificatesHistory: state => state.certificatesHistory,
      certificateHistoryTotal: state => state.certificateHistoryTotal,
      manufacturerDealers: state => state.partners,
      manufacturerDealersTotal: state => state.partnersTotal,
      selfretailsHistory: state => state.selfretailsHistory,
      selfretailsHistoryTotal: state => state.selfretailsHistoryTotal,
      selfretails: state => state.selfretails,
      selfretailsTotal: state => state.selfretailsTotal,
      manufacturerManager: state => state.manufacturerManager,
      certifyReReleaseHistory: state => state.certifyReReleaseHistory,
      certifyReReleaseHistoryTotal: state => state.certifyReReleaseHistory.length
    }),

    historyRenewCertificate() {
      return [];
    },

    historyRowSelected() {
      return !!this.historyRowSelect && this.historyRowSelect.status === "Активен";
    },
    selfretailsHistoryRowSelected() {
      return !!this.selfretailsHistoryRowSelect && this.selfretailsHistoryRowSelect.status === "Активен";
    },
    selfretailsRowSelected() {
      return !!this.selfretailsRowSelect && this.isAdmin;
    },
    isEditDisabled() {
      return !this.selfretailsRowSelect.isApproved;
    },

    showRenewButton() {
      return !this.isAdmin && !this.isCurator && this.tabIndex == 0 && this.historyRowSelect.showRereleaseButton;
    },
    showHistoryButton() {
      return (
        [0, 2].includes(this.tabIndex) &&
        ((!!this.historyRowSelect && this.historyRowSelect.numberOfRerelease > 0) || (!!this.selfretailsHistoryRowSelect && this.selfretailsHistoryRowSelect.numberOfRerelease > 0))
      );
    }
  },
  created() {
    this.fechAllViewData();
  }
};
</script>

<style lang="scss">
.col-full {
  flex-grow: 1;
  max-width: 100%;
}
</style>
